import React, { useRef, useLayoutEffect } from "react";
import { Link } from "gatsby"

import Layout from "../components/layout"
import Img from "gatsby-image"

import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import animateReveal from "../components/gs_reveal";
import { graphql } from "gatsby";
const slugify = require('slugify')

const user = {
    "España": [],
    "Catalunya": [
        "Abrera",
        "Aguilar de Segarra",
        "Aiguafreda",
        "Alella",
        "Alpens",
        "L' Ametlla del Vallès",
        "Arenys de Mar",
        "Arenys de Munt",
        "Argençola",
        "Argentona",
        "Artés",
        "Avià",
        "Avinyó",
        "Avinyonet del Penedès",
        "Badalona",
        "Badia del Vallès",
        "Bagà",
        "Balenyà",
        "Balsareny",
        "Barberà del Vallès",
        "Barcelona",
        "Begues",
        "Bellprat",
        "Berga",
        "Bigues i Riells",
        "Borredà",
        "El Bruc",
        "El Brull",
        "Les Cabanyes",
        "Cabrera de Mar",
        "Cabrera d'Igualada",
        "Cabrils",
        "Calaf",
        "Calders",
        "Caldes de Montbui",
        "Caldes d'Estrac",
        "Calella",
        "Calldetenes",
        "Callús",
        "Calonge de Segarra",
        "Campins",
        "Canet de Mar",
        "Canovelles",
        "Cànoves i Samalús",
        "Canyelles",
        "Capellades",
        "Capolat",
        "Cardedeu",
        "Cardona",
        "Carme",
        "Casserres",
        "Castell de l'Areny",
        "Castellar de n'Hug",
        "Castellar del Riu",
        "Castellar del Vallès",
        "Castellbell i el Vilar",
        "Castellbisbal",
        "Castellcir",
        "Castelldefels",
        "Castellet i la Gornal",
        "Castellfollit de Riubregós",
        "Castellfollit del Boix",
        "Castellgalí",
        "Castellnou de Bages",
        "Castellolí",
        "Castellterçol",
        "Castellví de la Marca",
        "Castellví de Rosanes",
        "Centelles",
        "Cercs",
        "Cerdanyola del Vallès",
        "Cervelló",
        "Collbató",
        "Collsuspina",
        "Copons",
        "Corbera de Llobregat",
        "Cornellà de Llobregat",
        "Cubelles",
        "Dosrius",
        "Esparreguera",
        "Esplugues de Llobregat",
        "L' Espunyola",
        "L' Estany",
        "Figaró-Montmany",
        "Fígols",
        "Fogars de la Selva",
        "Fogars de Montclús",
        "Folgueroles",
        "Fonollosa",
        "Font-rubí",
        "Les Franqueses del Vallès",
        "Gaià",
        "Gallifa",
        "La Garriga",
        "Gavà",
        "Gelida",
        "Gironella",
        "Gisclareny",
        "La Granada",
        "Granera",
        "Granollers",
        "Gualba",
        "Guardiola de Berguedà",
        "Gurb",
        "L' Hospitalet de Llobregat",
        "Els Hostalets de Pierola",
        "Igualada",
        "Jorba",
        "La Llacuna",
        "La Llagosta",
        "Lliçà d'Amunt",
        "Lliçà de Vall",
        "Llinars del Vallès",
        "Lluçà",
        "Malgrat de Mar",
        "Malla",
        "Manlleu",
        "Manresa",
        "Marganell",
        "Martorell",
        "Martorelles",
        "Les Masies de Roda",
        "Les Masies de Voltregà",
        "El Masnou",
        "Masquefa",
        "Matadepera",
        "Mataró",
        "Mediona",
        "Moià",
        "Molins de Rei",
        "Mollet del Vallès",
        "Monistrol de Calders",
        "Monistrol de Montserrat",
        "Montcada i Reixac",
        "Montclar",
        "Montesquiu",
        "Montgat",
        "Montmajor",
        "Montmaneu",
        "Montmeló",
        "Montornès del Vallès",
        "Montseny",
        "Muntanyola",
        "Mura",
        "Navarcles",
        "Navàs",
        "La Nou de Berguedà",
        "Òdena",
        "Olèrdola",
        "Olesa de Bonesvalls",
        "Olesa de Montserrat",
        "Olivella",
        "Olost",
        "Olvan",
        "Orís",
        "Oristà",
        "Orpí",
        "Òrrius",
        "Pacs del Penedès",
        "Palafolls",
        "Palau-solità i Plegamans",
        "Pallejà",
        "La Palma de Cervelló",
        "El Papiol",
        "Parets del Vallès",
        "Perafita",
        "Piera",
        "Pineda de Mar",
        "El Pla del Penedès",
        "La Pobla de Claramunt",
        "La Pobla de Lillet",
        "Polinyà",
        "El Pont de Vilomara i Rocafort",
        "Pontons",
        "El Prat de Llobregat",
        "Prats de Lluçanès",
        "Els Prats de Rei",
        "Premià de Dalt",
        "Premià de Mar",
        "Puigdàlber",
        "Puig-reig",
        "Pujalt",
        "La Quar",
        "Rajadell",
        "Rellinars",
        "Ripollet",
        "La Roca del Vallès",
        "Roda de Ter",
        "Rubí",
        "Rubió",
        "Rupit i Pruit",
        "Sabadell",
        "Sagàs",
        "Saldes",
        "Sallent",
        "Sant Adrià de Besòs",
        "Sant Agustí de Lluçanès",
        "Sant Andreu de la Barca",
        "Sant Andreu de Llavaneres",
        "Sant Antoni de Vilamajor",
        "Sant Bartomeu del Grau",
        "Sant Boi de Llobregat",
        "Sant Boi de Lluçanès",
        "Sant Cebrià de Vallalta",
        "Sant Celoni",
        "Sant Climent de Llobregat",
        "Sant Cugat del Vallès",
        "Sant Cugat Sesgarrigues",
        "Sant Esteve de Palautordera",
        "Sant Esteve Sesrovires",
        "Sant Feliu de Codines",
        "Sant Feliu de Llobregat",
        "Sant Feliu Sasserra",
        "Sant Fost de Campsentelles",
        "Sant Fruitós de Bages",
        "Sant Hipòlit de Voltregà",
        "Sant Iscle de Vallalta",
        "Sant Jaume de Frontanyà",
        "Sant Joan de Vilatorrada",
        "Sant Joan Despí",
        "Sant Julià de Cerdanyola",
        "Sant Julià de Vilatorta",
        "Sant Just Desvern",
        "Sant Llorenç d'Hortons",
        "Sant Llorenç Savall",
        "Sant Martí d'Albars",
        "Sant Martí de Centelles",
        "Sant Martí de Tous",
        "Sant Martí Sarroca",
        "Sant Martí Sesgueioles",
        "Sant Mateu de Bages",
        "Sant Pere de Ribes",
        "Sant Pere de Riudebitlles",
        "Sant Pere de Torelló",
        "Sant Pere de Vilamajor",
        "Sant Pere Sallavinera",
        "Sant Pol de Mar",
        "Sant Quintí de Mediona",
        "Sant Quirze de Besora",
        "Sant Quirze del Vallès",
        "Sant Quirze Safaja",
        "Sant Sadurní d'Anoia",
        "Sant Sadurní d'Osormort",
        "Sant Salvador de Guardiola",
        "Sant Vicenç de Castellet",
        "Sant Vicenç de Montalt",
        "Sant Vicenç de Torelló",
        "Sant Vicenç dels Horts",
        "Santa Cecília de Voltregà",
        "Santa Coloma de Cervelló",
        "Santa Coloma de Gramenet",
        "Santa Eugènia de Berga",
        "Santa Eulàlia de Riuprimer",
        "Santa Eulàlia de Ronçana",
        "Santa Fe del Penedès",
        "Santa Margarida de Montbui",
        "Santa Margarida i els Monjos",
        "Santa Maria de Besora",
        "Santa Maria de Corcó",
        "Santa Maria de Martorelles",
        "Santa Maria de Merlès",
        "Santa Maria de Miralles",
        "Santa Maria de Palautordera",
        "Santa Maria d'Oló",
        "Santa Perpètua de Mogoda",
        "Santa Susanna",
        "Santpedor",
        "Sentmenat",
        "Seva",
        "Sitges",
        "Sobremunt",
        "Sora",
        "Subirats",
        "Súria",
        "Tagamanent",
        "Talamanca",
        "Taradell",
        "Tavèrnoles",
        "Tavertet",
        "Teià",
        "Terrassa",
        "Tiana",
        "Tona",
        "Tordera",
        "Torelló",
        "La Torre de Claramunt",
        "Torrelavit",
        "Torrelles de Foix",
        "Torrelles de Llobregat",
        "Ullastrell",
        "Vacarisses",
        "Vallbona d'Anoia",
        "Vallcebre",
        "Vallgorguina",
        "Vallirana",
        "Vallromanes",
        "Veciana",
        "Vic",
        "Vilada",
        "Viladecans",
        "Viladecavalls",
        "Vilafranca del Penedès",
        "Vilalba Sasserra",
        "Vilanova de Sau",
        "Vilanova del Camí",
        "Vilanova del Vallès",
        "Vilanova i la Geltrú",
        "Vilassar de Dalt",
        "Vilassar de Mar",
        "Vilobí del Penedès",
        "Viver i Serrateix",
    ],
    "Madrid": [
        "La Acebeda",
        "Ajalvir",
        "Alameda del Valle",
        "El Álamo",
        "Alcalá de Henares",
        "Alcobendas",
        "Alcorcón",
        "Aldea del Fresno",
        "Algete",
        "Alpedrete",
        "Ambite",
        "Anchuelo",
        "Aranjuez",
        "Arganda del Rey",
        "Arroyomolinos",
        "El Atazar",
        "Batres",
        "Becerril de la Sierra",
        "Belmonte de Tajo",
        "El Berrueco",
        "Berzosa del Lozoya",
        "Boadilla del Monte",
        "El Boalo",
        "Braojos",
        "Brea de Tajo",
        "Brunete",
        "Buitrago del Lozoya",
        "Bustarviejo",
        "Cabanillas de la Sierra",
        "La Cabrera",
        "Cadalso de los Vidrios",
        "Camarma de Esteruelas",
        "Campo Real",
        "Canencia",
        "Carabaña",
        "Casarrubuelos",
        "Cenicientos",
        "Cercedilla",
        "Cervera de Buitrago",
        "Chapinería",
        "Chinchón",
        "Ciempozuelos",
        "Cobeña",
        "Collado Mediano",
        "Collado Villalba",
        "Colmenar de Oreja",
        "Colmenar del Arroyo",
        "Colmenar Viejo",
        "Colmenarejo",
        "Corpa",
        "Coslada",
        "Cubas de la Sagra",
        "Daganzo de Arriba",
        "El Escorial",
        "Estremera",
        "Fresnedillas de la Oliva",
        "Fresno de Torote",
        "Fuenlabrada",
        "Fuente el Saz de Jarama",
        "Fuentidueña de Tajo",
        "Galapagar",
        "Garganta de los Montes",
        "Gargantilla del Lozoya y Pinilla de Buitrago",
        "Gascones",
        "Getafe",
        "Griñón",
        "Guadalix de la Sierra",
        "Guadarrama",
        "La Hiruela",
        "Horcajo de la Sierra",
        "Horcajuelo de la Sierra",
        "Hoyo de Manzanares",
        "Humanes de Madrid",
        "Leganés",
        "Loeches",
        "Lozoya",
        "Lozoyuela-Navas-Sieteiglesias",
        "Madarcos",
        "Madrid",
        "Majadahonda",
        "Manzanares el Real",
        "Meco",
        "Mejorada del Campo",
        "Miraflores de la Sierra",
        "El Molar",
        "Los Molinos",
        "Montejo de la Sierra",
        "Moraleja de Enmedio",
        "Moralzarzal",
        "Morata de Tajuña",
        "Móstoles",
        "Navacerrada",
        "Navalafuente",
        "Navalagamella",
        "Navalcarnero",
        "Navarredonda y San Mamés",
        "Navas del Rey",
        "Nuevo Baztán",
        "Olmeda de las Fuentes",
        "Orusco de Tajuña",
        "Paracuellos de Jarama",
        "Parla",
        "Patones",
        "Pedrezuela",
        "Pelayos de la Presa",
        "Perales de Tajuña",
        "Pezuela de las Torres",
        "Pinilla del Valle",
        "Pinto",
        "Piñuécar-Gandullas",
        "Pozuelo de Alarcón",
        "Pozuelo del Rey",
        "Prádena del Rincón",
        "Puebla de la Sierra",
        "Puentes Viejas",
        "Quijorna",
        "Rascafría",
        "Redueña",
        "Ribatejada",
        "Rivas-Vaciamadrid",
        "Robledillo de la Jara",
        "Robledo de Chavela",
        "Robregordo",
        "Las Rozas de Madrid",
        "Rozas de Puerto Real",
        "San Agustín del Guadalix",
        "San Fernando de Henares",
        "San Lorenzo de El Escorial",
        "San Martín de la Vega",
        "San Martín de Valdeiglesias",
        "San Sebastián de los Reyes",
        "Santa María de la Alameda",
        "Santorcaz",
        "Los Santos de la Humosa",
        "La Serna del Monte",
        "Serranillos del Valle",
        "Sevilla la Nueva",
        "Somosierra",
        "Soto del Real",
        "Talamanca de Jarama",
        "Tielmes",
        "Titulcia",
        "Torrejón de Ardoz",
        "Torrejón de la Calzada",
        "Torrejón de Velasco",
        "Torrelaguna",
        "Torrelodones",
        "Torremocha de Jarama",
        "Torres de la Alameda",
        "Tres Cantos",
        "Valdaracete",
        "Valdeavero",
        "Valdelaguna",
        "Valdemanco",
        "Valdemaqueda",
        "Valdemorillo",
        "Valdemoro",
        "Valdeolmos-Alalpardo",
        "Valdepiélagos",
        "Valdetorres de Jarama",
        "Valdilecha",
        "Valverde de Alcalá",
        "Velilla de San Antonio",
        "El Vellón",
        "Venturada",
        "Villa del Prado",
        "Villaconejos",
        "Villalbilla",
        "Villamanrique de Tajo",
        "Villamanta",
        "Villamantilla",
        "Villanueva de la Cañada",
        "Villanueva de Perales",
        "Villanueva del Pardillo",
        "Villar del Olmo",
        "Villarejo de Salvanés",
        "Villaviciosa de Odón",
        "Villavieja del Lozoya"
    ]
};

export default function ReformasCiudad({ location, data }) {
    gsap.registerPlugin(ScrollTrigger);
    const revealRefs = useRef([]);
    revealRefs.current = [];
    useLayoutEffect(() => {
        gsap.registerPlugin(ScrollTrigger)
        let scrollTriggers = [];
        scrollTriggers = animateReveal(revealRefs.current);
        return () => {
            scrollTriggers.forEach((t) => t.kill(true));
        };
    }, []);

    const addToRevealRefs = (el) => {
        if (el && !revealRefs.current.includes(el)) {
            revealRefs.current.push(el);
        }
    };
    let ciudad = "España"

    if (location.state) {
        ciudad = location.state.ciudad
    }

    const names = Object.keys(user)
        .filter((key) => key.includes(ciudad))
        .reduce((obj, key) => {
            return Object.assign(obj, {
                "ciudad": user[key]
            });
        }, []);
    return (
        <Layout>
            <div
                className="bg-[url('https://reformas.top/background/reformas-integrales-ciudad.webp')] bg-fixed bg-cover bg-bottom bg-no-repeat mb-20">
                <div className=" container py-20">
                    <div className=" 2xl:px-10">
                        <h1
                            className="text-center text-5xl tracking-tight sm:text-6xl lg:text-7xl text-white max-w-5xl mx-auto">
                            <strong>Transforma</strong> tu hogar con <strong>Reformas integrales</strong>
                        </h1>
                        <p className="mt-6 max-w-lg mx-auto text-center text-2xl text-white sm:max-w-3xl"> Realiza reformas,
                            pinta tu
                            hogar, construye un espacio nuevo y mucho más a tan solo un click.</p>
                        <div className="mt-10 max-w-sm mx-auto sm:max-w-none sm:flex sm:justify-center">
                            <div className="space-y-4 sm:space-y-0 sm:mx-auto sm:inline-grid sm:grid-cols-2 sm:gap-5">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section>
                <div className="container mb-20 ">
                    <div className="lg:max-w-6xl mx-auto mb-20">
                        <h2 className="text-4xl lg:text-6xl text-center lg:leading-normal	">Nuestros servicios en
                            Contrucciones y reformas en {ciudad}
                        </h2>
                    </div>
                    <div className="grid grid-cols-2 md:grid-cols-4 gap-5 ">
                        {
                            names.ciudad.map((item, i) => {
                                return <div key={i} className="flex items-center justify-center shadow-md hover:shadow-xl text-center py-4 rounded-2xl hover:bg-primary hover:text-white hover:cursor-pointer transition-all duration-500 hover:-translate-y-1" >
                                <span>
                                    <Link to={`/reformas-en-${slugify(item, {lower: true,   remove: /[*+~.()'"!:@]/g })}` }>
                                        {item}
                                    </Link>
                                </span>
                                </div>
                            })
                        }
                    </div>
                </div>
            </section>
            <section>
                <div className="container  mb-20 ">
                    <div className="flex flex-col-reverse lg:flex-row items-center lg:w-11/12 mx-auto">
                        <div ref={addToRevealRefs} className="gs_reveal_fromLeft basis-1/2 bg-gray-bg bg-opacity-25 lg:rounded-l-3xl p-5 lg:p-10">
                            <h2 className="mb-5">Reformas de Oficinas</h2>
                            <p>Las <strong>reformas de oficinas en {ciudad}</strong> son parte de nuestra especialidad, sobre todo porque nos
                                adaptamos a los plazos más estrictos y sabemos cómo adecuar un proyecto a las necesidades de
                                cada empresa.
                            </p>
                        </div>
                        <div ref={addToRevealRefs} className="basis-1/2 mb-5 ">
                            <Img fluid={data.oficinas.childImageSharp.fluid} alt="Reformas de Oficinas" />
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className="container mb-20 ">
                    <div className="flex flex-col lg:flex-row items-center  lg:w-11/12 mx-auto">
                        <div ref={addToRevealRefs} className="basis-1/2 mb-5">
                            <Img fluid={data.viviendas.childImageSharp.fluid} alt="Reformas de Viviendas" />
                        </div>
                        <div ref={addToRevealRefs} className="gs_reveal_fromRight basis-1/2 bg-gray-bg bg-opacity-25 lg:rounded-r-3xl p-5 lg:p-10">
                            <h2 className="mb-5">Reformas de Viviendas</h2>
                            <p>Las tiendas predilectas de los clientes son las que cuentan con un ambiente cómodo y moderno,
                                así que si tus ventas no son las que esperas, es posible que tus clientes no se sientan a
                                gusto en tu local. Solicita una reforma a nuestra empresa y verás cómo tu tienda te dará los
                                dividendos que deseas.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className="container  mb-20 ">
                    <div className="flex flex-col-reverse lg:flex-row items-center lg:w-11/12 mx-auto">
                        <div ref={addToRevealRefs} className="gs_reveal_fromLeft basis-1/2 bg-gray-bg bg-opacity-25 lg:rounded-l-3xl p-5 lg:p-10">
                            <h2 className="mb-5">Reformas de Comercios</h2>
                            <p>Los bares son sitios que pasan de moda rápidamente y los estilos de diseño inciden en la
                                afluencia de clientes. Es por eso que las <strong>reformas de bares en {ciudad}</strong> son muy solicitadas
                                y nosotros conocemos las nuevas tendencias para que tengas el bar más moderno y concurrido
                                de <strong>{ciudad}</strong>.
                            </p>
                        </div>
                        <div ref={addToRevealRefs} className="basis-1/2 mb-5">
                            <Img fluid={data.oficinas.childImageSharp.fluid} alt={`Reformas de Comercio en ${ciudad}`} />
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export const query = graphql`
query ReformasIntegrales {
    oficinas:file(relativePath: {eq: "reformas-integrales-de-oficinas.webp"}) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    },
    viviendas:file(relativePath: {eq: "reformas-de-viviendas.webp"}) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      },
    comercios:file(relativePath: {eq: "reformas-de-bares.webp"}) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      },
  }
`

